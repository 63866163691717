'use client'
import { useState, useEffect, useRef } from 'react'

import { fullstory } from '@/modules/analytics/track'

export const LanderTitle = ({ title }: { title: string }) => {
  useEffect(() => {
    fullstory().pageProperties({ landerH1: title })
  }, [])

  return <span>{title}</span>
}
