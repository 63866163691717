'use client'
import { useEffect, useState } from 'react'
import browserStorage from '@/lib/browserStorage'

const breakpoints = [
  'large-desk',
  'desk',
  'lap',
  'palm-medium',
  'palm-small',
  'palm-smallest',
]

export const DevMenu = () => {
  const [devMode, setDevMode] = useState(false)

  useEffect(() => {
    setDevMode(
      browserStorage.usable() ? !!browserStorage.get('devMode') : false
    )
  }, [])

  return devMode ? (
    <p
      style={{
        position: 'fixed',
        right: 10,
        top: 10,
        background: 'rgba(0,0,0,.7)',
        color: '#fff',
        borderRadius: 4,
        padding: 10,
        zIndex: 1000,
      }}
    >
      Breakpoint:{' '}
      {breakpoints.map(x => (
        <span key={x} className={`only-${x}`}>
          {x}
        </span>
      ))}
    </p>
  ) : null
}
