'use client'
import { useState, useEffect, useRef } from 'react'
import Link from 'next/link'

export const SignInOrUp = ({
  pageTitle,
  className,
  loginUrl,
  joinUrl,
}: {
  pageTitle: string
  className: string
  loginUrl: string
  joinUrl: string
}) => {
  return (
    <p className={className}>
      <Link href={loginUrl} className="btn btn--cta btn--primary">
        Sign In
      </Link>{' '}
      <span style={{ display: 'block', margin: '1em 0' }}>or</span>
      <Link
        href={joinUrl}
        className="btn btn--cta btn--secondary btn--square btn--wide btn--home-signup tw-px-8"
      >
        <span>Sign up free</span>
        <span className="tw-text-sm tw-text-nowrap">
          Join over 2.5m doctors globally
        </span>
      </Link>
    </p>
  )
}
