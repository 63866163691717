'use client'
import React, { useState, useEffect, useRef, ForwardedRef } from 'react'
import Image, { StaticImageData } from 'next/image'

export function useIsIntersecting(ref, observerOptions = { threshold: 0.5 }) {
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting)
    }, observerOptions)

    observer.observe(ref.current)
    return () => {
      observer.disconnect()
    }
  }, [ref])

  return isIntersecting
}

export const PlayWhenVideoVisible = ({
  poster,
  src,
  alt,
  className,
}: {
  poster: StaticImageData
  src: string
  alt: string
  className?: string
}) => {
  const [showVideo, setShowVideo] = useState(false)
  const imgRef = useRef()
  const isVisible = useIsIntersecting(imgRef)
  useEffect(() => {
    if (isVisible) {
      setTimeout(() => setShowVideo(true), 500)
    }
  }, [isVisible])

  if (showVideo) {
    return (
      <Video
        key={`${src}:${showVideo}`}
        autoPlay
        className={className}
        src={src}
      />
    )
  }

  return (
    <a href={src}>
      <Image
        alt={alt}
        ref={imgRef}
        src={poster}
        sizes="100vw"
        style={{ width: 832, height: 'auto' }}
        className={className}
      />
    </a>
  )
}

export const Video = React.forwardRef(
  (
    {
      src,
      poster,
      className,
      autoPlay,
    }: {
      src: string
      poster?: string
      className?: string
      autoPlay?: boolean
    },
    videoRef: ForwardedRef<HTMLVideoElement> | undefined
  ) => {
    return (
      <video
        ref={videoRef}
        className={className}
        poster={poster}
        controls
        autoPlay={!!autoPlay}
        muted={!!autoPlay}
      >
        <source src={src} type="video/mp4" />
      </video>
    )
  }
)
